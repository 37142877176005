<template>
  <div>
    <div id="wa_calendarHands_band" class="wa_center">
      <img src="@/assets/images/w-calendar_hands.png" alt="Tablet calendar" />
    </div>
    <div id="wa_bottomMenu">
      <div class="wa_container">
        <div class="wa_right wa_floatRight">
          <a href="https://www.facebook.com/pages/Web-appointments/135770266506974?fref=ts" target="_blank"><img src="@/assets/images/rg-facebook.png" alt="Facebook" /></a>
        </div>
        <!--<router-link to="/home.php" target="_blank">Blog</router-link><br>-->
        <router-link to="/">Home</router-link><br>
        <router-link to="Signup">Sign up</router-link><br>
        <!--<router-link to="/?page=resellers" target="_blank">Partner with us</router-link><br>-->
        <router-link to="Privacy">Privacy</router-link> & <router-link to="Terms">terms</router-link><br>
        <br>
        <a href="mailto:support@instant-scheduling.com?subject=Questions" target="_blank">Contact us</a><br>
        <br><br>
        <div class="wa_center">&copy;2005-{{ new Date().getFullYear() }} 2NAPPS</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar'
}
</script>
