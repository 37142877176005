<template>
  <!-- this is our top menu bar as shown on most pages -->
  <div id="wa_topMenu">
    <div>
      <div class="wa_floatRight">
        <router-link to="Signup" class="wa_positive" id="wa_menu_signup">try us</router-link>
        <a href="https://instant-scheduling.com">log in</a>
      </div>
      <!--<a href="http://online-scheduling.blogspot.com/" target="_blank">blog</a>-->
      <a href="mailto:support@instant-scheduling.com?subject=Questions" target="_blank">help</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar'
}
</script>
