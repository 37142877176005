<template>
  <div>
    <TopBar/>

    <router-view/>

    <BottomBar/>
  </div>
</template>

<script>
import TopBar from './components/TopBar'
import BottomBar from './components/BottomBar'

export default {
  name: 'Website',

  components: {
    TopBar,
    BottomBar
  },
}
</script>

<style lang="scss">
// Theme import
@import url('//fonts.googleapis.com/css?family=Roboto:300,500');
@import './assets/theme.sass';
</style>
