<template>
  <div class="wa_buttonBand">
    <router-link to="Signup">
      <button type="button" class="wa_button wa_positive"><img src="@/assets/images/wp-checked_squared.png" alt="Collaboration" class="wa_img_checked" /><br>Try us free</button>
    </router-link>
    <br>
    <a href="mailto:support@instant-scheduling.com?subject=Questions" target="_blank">ask a question</a>
  </div>
</template>

<script>
export default {
  name: 'SignupBar'
}
</script>
