import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Online Scheduling Software - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: 'Easy online appointment system for your business. Let your clients, your staff, or both, schedule online and see availabilities in real time. Reduce friction and allow people to book quickly and effortlessly.'
        }
      ]
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue'),
    meta: {
      title: 'Terms and conditions - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: 'Your access to and browsing through our websites constitute your full acceptance of the Terms and Conditions outlined on this page.'
        }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
    meta: {
      title: 'Privacy policy - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: 'This policy covers how 2NAPPS treats personal information that we collect and receive. 2NAPPS will use your personal information only in ways that are compatible with this privacy policy.'
        }
      ]
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
    meta: {
      title: 'Free trial - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: 'The first 30 days are on us. Try our online scheduling solution with no limitations today.'
        }
      ]
    }
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: () => import(/* webpackChunkName: "about" */ '../views/Thankyou.vue'),
    meta: {
      title: 'Thank you! - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: "Now you're ready to head over to instant-scheduling.com."
        }
      ]
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
    meta: {
      title: 'Not found! - Web Appointments',
      metaTags: [
        {
          name: 'description',
          content: "Page not found. Easy online appointment system for your business, visit the home page."
        }
      ]
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
