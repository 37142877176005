<template>
  <div>
    <div id="wa_title"><div><img src="@/assets/images/w-wa_logo.png" alt="Logo" class="wa_center" /><br><span class="wa_small">Online scheduling software</span></div></div>
    
    <div id="wa_descriptionBand">
      <div>Let your clients, your staff, or both, schedule online and see availabilities in real time. Reduce friction and allow people to book quickly and effortlessly.</div>
      <br>
      <!--<picture>
        <source srcset="cool-pic.webp" type="image/webp">
        <source srcset="cool-pic.jpg" type="image/jpeg"> 
        <img src="cool-pic.jpg">
        <img srcset="img-orig.jpg,
             img-2x.jpg 2x,
             img-3x.jpg 3x"
              src="img-orig.jpg" />
        <source srcset="image-800.webp 800w, image-400.webp 400w, image-200.webp 200w" 
          type='image/webp' media="(max-width: 800px)" 
          sizes="100vw" />
        <img src="default.webp" alt="something" />
      </picture> -->
      <img src="@/assets/images/w-admin_area.png" alt="Adding appointments" />
      <!-- <img src="@/assets/images/w-into_calendar.png" alt="Adding appointments" /> -->
    </div>

    <div class="wa_explanationBand">
      <div class="wa_center">Works for any business, from sole proprietorships to multi-location companies.</div>
      <div class="wa_imageRow">
        <img src="@/assets/images/b-doctor.png" alt="Single professional" />
        <img src="@/assets/images/b-school.png" alt="Small team" />
        <img src="@/assets/images/g-globe.png" alt="Multiple locations" />
      </div>
      <div class="wa_center">In almost any industry needing to accept or manage appointments on the web.</div>
    </div>

    <div class="wa_explanationBand">
      <div>
        <h2 class="wa_header">A few examples</h2>
        The demos are all set up differently to showcase different scenarios:<br><br>
        <div class="wa_demos">
          <a href="https://instant-scheduling.com/sch.php?kn=168" target="_blank">massage therapist</a>
          <a href="https://instant-scheduling.com/sch.php?kn=169" target="_blank">fitness club</a>
          <a href="https://instant-scheduling.com/sch.php?kn=170" target="_blank">tennis club</a>
          <a href="https://instant-scheduling.com/sch.php?kn=171" target="_blank">physician</a>
          <a href="https://instant-scheduling.com/sch.php?kn=172" target="_blank">dentist</a>
          <a href="https://instant-scheduling.com/sch.php?kn=173" target="_blank">beauty salon</a>
          <a href="https://instant-scheduling.com/sch.php?kn=165" target="_blank">realtor</a>
        </div>
      <br>
      </div>
    </div>

    <div class="wa_gridBand wa_featuresBand">
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-booking_steps.png" alt="3 simple steps" /></div>
        <h2 class="wa_header">Simplicity is key</h2>
        <div class="wa_text">A simple booking process with no separate registration to encourage more bookings. Only what matters to save everybody time.</div>
      </div>
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-tools.png" alt="Booking tools" /></div>
        <h2 class="wa_header">Website optional</h2>
        <div class="wa_text">Take appointments over the phone, on Facebook, from your email signature and yes, on your website or simply from your own domain name.</div>
      </div>
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-robot.png" alt="Reminders and notifications" /></div>
        <h2 class="wa_header">Handy reminders</h2>
        <div class="wa_text">Reduce no shows, we can notify staff and clients via email. We can even text clients just before their appointment.</div>
      </div>
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-flexible_rules.png" alt="Flexible rules" /></div>
        <h2 class="wa_header">Flexible rules</h2>
        <div class="wa_text">Schedule irregular hours, allow some things at certain times with different rules, and more. Chances are we can match your current practices.</div>
      </div>
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-cloud_devices.png" alt="All your devices" /></div>
        <h2 class="wa_header">Check anywhere</h2>
        <div class="wa_text">See appointments on your preferred device using your preferred software. Export to Outlook, Google, iCloud, spreadsheets and more.</div>
      </div>
      <div class="wa_gridItem">
        <div class="wa_center"><img src="@/assets/images/w-unlimited.png" alt="No limits" /></div>
        <h2 class="wa_header">No usage limits</h2>
        <div class="wa_text">Unlimited number of staff, clients, appointments, services, booking rules, connected devices, data exports...</div>
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div><h2 class="wa_header">One low monthly fee</h2>Everything is included, we have no extra charges. Your first month is free (so you can <a href="sign_up.php">try us out</a>) and after that you can buy additional time as needed.<br><br>Prices are in USD or we'll charge you the equivalent in your local currency.<br><br>
        <table id="wa_prices">
          <tr>
            <th>Prepay for</th>
            <!-- <th>You pay</th> -->
            <th>Cost /mth</th>
          </tr>
          <tr>
            <td>1 <span class="wa_faded">mth</span></td>
            <!-- <td><span class="wa_faded">$</span>15.90</td> -->
            <td><span class="wa_faded">$</span>15.90</td>
          </tr>
          <!-- <tr>
            <td>3 <span class="wa_faded">mths</span></td>
            <td><span class="wa_faded">$</span>35.70</td>
            <td><span class="wa_faded">$</span>11.90</td>
          </tr> -->
          <tr>
            <td class="wa_highlight">12 <span class="wa_faded">mths</span></td>
            <!-- <td class="wa_highlight"><span class="wa_faded">$</span>118.80</td> -->
            <td class="wa_highlight"><span class="wa_faded">$</span>9.90</td>
          </tr>
        </table>
      </div>
    </div>

    <SignupBar/>
  </div>
</template>

<script>
import SignupBar from '../components/SignupBar'

export default {
  name: 'Home',
  components: {
    SignupBar
  }
}
</script>
